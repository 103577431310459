import React, { createContext, useState, useEffect } from "react";
import productData from "../Datas/productData";
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (
    productId,
    quantity,
    size,
    color,
    length,
    category,
    backboard,
    rail,
    panels,
    sites,
    depth,
    type,
    width,
    height,
    postage,
    delivery,
    cancellation
  ) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) =>
          item.id === productId &&
          item.size === size &&
          item.color === color &&
          item.length === length &&
          item.category === category &&
          item.backboard === backboard &&
          item.rail === rail &&
          item.panels === panels &&
          item.sites === sites &&
          item.depth === depth &&
          item.type === type &&
          item.width === width &&
          item.height === height &&
          item.postage === postage &&
          item.delivery === delivery &&
          item.cancellation === cancellation
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        const newItem = {
          id: productId,
          quantity,
          ...(size && { size }),
          ...(color && { color }),
          ...(length && { length }),
          ...(category && { category }),
          ...(backboard && { backboard }),
          ...(rail && { rail }),
          ...(panels && { panels }),
          ...(sites && { sites }),
          ...(depth && { depth }),
          ...(type && { type }),
          ...(width && { width }),
          ...(height && { height }),
          ...(postage && { postage }),
          ...(delivery && { delivery }),
          ...(cancellation && { cancellation }),
        };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (
    productId,
    size,
    color,
    length,
    category,
    backboard,
    rail,
    panels,
    sites,
    depth,
    type,
    width,
    height,
    postage,
    delivery,
    cancellation
  ) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => {
        const matchesId = item.id === productId;
        const matchesSize = size ? item.size === size : true;
        const matchesColor = color ? item.color === color : true;
        const matchesLength = length ? item.length === length : true;
        const matchesCategory = category ? item.category === category : true;
        const matchesBackboard = backboard
          ? item.backboard === backboard
          : true;
        const matchesRail = rail ? item.rail === rail : true;
        const matchesPanels = panels ? item.panels === panels : true;
        const matchesSites = sites ? item.sites === sites : true;
        const matchesDepth = depth ? item.depth === depth : true;
        const matchesType = type ? item.type === type : true;
        const matchesWidth = width ? item.width === width : true;
        const matchesHeight = height ? item.height === height : true;
        const matchesPostage = postage ? item.postage === postage : true;
        const matchesDelivery = delivery ? item.delivery === delivery : true;
        const matchesCancellation = cancellation
          ? item.cancellation === cancellation
          : true;

        return !(
          matchesId &&
          matchesSize &&
          matchesColor &&
          matchesLength &&
          matchesCategory &&
          matchesBackboard &&
          matchesRail &&
          matchesPanels &&
          matchesSites &&
          matchesDepth &&
          matchesType &&
          matchesWidth &&
          matchesHeight &&
          matchesPostage &&
          matchesDelivery &&
          matchesCancellation
        );
      })
    );
  };

  const updateQuantity = (
    productId,
    quantity,
    size,
    color,
    length,
    category,
    backboard,
    rail,
    panels,
    sites,
    depth,
    type,
    width,
    height,
    postage,
    delivery,
    cancellation
  ) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        const matchesId = item.id === productId;
        const matchesSize = size ? item.size === size : true;
        const matchesColor = color ? item.color === color : true;
        const matchesLength = length ? item.length === length : true;
        const matchesCategory = category ? item.category === category : true;
        const matchesBackboard = backboard
          ? item.backboard === backboard
          : true;
        const matchesRail = rail ? item.rail === rail : true;
        const matchesPanels = panels ? item.panels === panels : true;
        const matchesSites = sites ? item.sites === sites : true;
        const matchesDepth = depth ? item.depth === depth : true;
        const matchesType = type ? item.type === type : true;
        const matchesWidth = width ? item.width === width : true;
        const matchesHeight = height ? item.height === height : true;
        const matchesPostage = postage ? item.postage === postage : true;
        const matchesDelivery = delivery ? item.delivery === delivery : true;
        const matchesCancellation = cancellation
          ? item.cancellation === cancellation
          : true;

        return matchesId &&
          matchesSize &&
          matchesColor &&
          matchesLength &&
          matchesCategory &&
          matchesBackboard &&
          matchesRail &&
          matchesPanels &&
          matchesSites &&
          matchesDepth &&
          matchesType &&
          matchesWidth &&
          matchesHeight &&
          matchesPostage &&
          matchesDelivery &&
          matchesCancellation
          ? { ...item, quantity }
          : item;
      })
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
