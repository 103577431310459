const productData = [
  {
    id: 1,
    name: "天然木、無垢材を使用したハイチェスト 彩美／SAIBI-幅1200mm（ウォールナット・ウォルナット-シンプル） md",
    price: "177000",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: ["なし　(通常450mm)", "+50mm　(総奥行500mm)"],
    type: [],
    width: [],
    height: [],
    postage: [],
    delivery: [],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "https://kyno.jp/images/items/brand/saibi/110000000/110001200/planandspec-s1.gif",
      "https://kyno.jp/images/items/brand/saibi/110000000/110001200/planandspec-s2.gif",
    ],
    size: [],
    color: [],
    images: [
      "/kyno/1001.jpg",
      "/kyno/1001a.jpg",
      "/kyno/1001b.jpg",
      "/kyno/1001c.jpg",
      "/kyno/1001d.jpg",
      "/kyno/1001e.jpg",
      "/kyno/1001f.jpg",
      "/kyno/1001g.jpg",
      "/kyno/1001h.jpg",
      "/kyno/1001i.gif",
    ],
  },
  {
    id: 2,
    name: "凛／RIN　センターテーブル(四角脚) ローテーブル リビングテーブル 幅1000mm（メープル）",
    price: "47000",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: ["500mm（通常）", "600mm"],
    type: ["オイル塗装+蜜蝋ワックス仕上げ", "セラウッド塗装"],
    width: ["800", "900", "1000（通常）", "1100", "1200"],
    height: [
      "320mm",
      "330mm",
      "340mm",
      "350mm",
      "360mm",
      "370mm",
      "380mm",
      "390mm",
      "400mm",
    ],
    postage: ["北海道以外の地域", "北海道"],
    delivery: [],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "https://kyno.jp/images/items/brand/rin/081000000/081001000/spec-s01.gif",
      "https://kyno.jp/images/items/brand/rin/081000000/081001000/spec-s02.gif",
    ],
    size: [],
    color: [],
    images: [
      "/kyno/1002.jpg",
      "/kyno/1002a.jpg",
      "/kyno/1002b.jpg",
      "/kyno/1002c.jpg",
      "/kyno/1002d.jpg",
      "/kyno/1002e.jpg",
      "/kyno/1002f.jpg",
      "/kyno/1002g.gif",
    ],
  },
  {
    id: 3,
    name: "【サイズオーダー可】天然木、無垢材使用テレビボード 風雅／FUUGA-幅1500mm（ウォールナット・ウォルナット‐スリット）st",
    price: "121000",
    backboard: ["希望しない（背板あり）", "背板なし"],
    rail: ["通常レール", "ソフトクローズレール"],
    panels: ["ツキ板（通常）", "無垢"],
    sites: ["スリットあり", "スリットなし"],
    depth: ["なし（通常450mm）", "+50mm（総奥行500mm）"],
    type: ["通常：切り欠き", "金具あり配線孔"],
    width: [],
    height: [
      "なし（通常310mm）",
      "+25mm（総高さ335mm）",
      "+50mm（総高さ360mm）",
    ],
    postage: ["北海道以外の地域", "北海道"],
    delivery: [],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "https://kyno.jp/images/items/brand/fuuga2/070000000/type1_700.jpg",
      "https://kyno.jp/images/items/brand/fuuga/fuuga_tv_category/fuuga_chigai.jpg",
      "https://kyno.jp/images/items/brand/fuuga/070000000/070001500/spec-s01.gif",
      "https://kyno.jp/images/items/brand/fuuga/070000000/070001500/spec-s01.gif",
      "https://kyno.jp/images/items/brand/fuuga/070000000/070001500/spec-s03.gif",
    ],
    size: [],
    color: [],
    images: [
      "/kyno/1003.jpg",
      "/kyno/1003a.jpg",
      "/kyno/1003b.jpg",
      "/kyno/1003c.jpg",
      "/kyno/1003d.jpg",
      "/kyno/1003e.jpg",
      "/kyno/1003f.jpg",
      "/kyno/1003g.jpg",
      "/kyno/1003h.jpg",
      "/kyno/1003i.jpg",
      "/kyno/1003j.jpg",
      "/kyno/1003k.jpg",
      "/kyno/1003l.jpg",
      "/kyno/1003m.jpg",
      "/kyno/1003n.jpg",
      "/kyno/1003o.jpg",
      "/kyno/1003p.jpg",
      "/kyno/1003q.jpg",
    ],
  },
  {
    id: 4,
    name: "天然木、無垢材を使用したハイチェスト 彩美／SAIBI-幅750mm（ホワイトオーク-シンプル）md",
    price: "112000",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: ["なし　(通常450mm)", "+50mm　(総奥行500mm)"],
    type: [],
    width: [],
    height: [],
    postage: [],
    delivery: [],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "https://kyno.jp/images/items/brand/saibi/110000000/110000750/planandspec-s1.gif",
      "https://kyno.jp/images/items/brand/saibi/110000000/110000750/planandspec-s2.gif",
      "https://kyno.jp/images/items/information/attention-1.gif",
    ],
    size: [],
    color: [],
    images: [
      "/kyno/1004.jpg",
      "/kyno/1004a.jpg",
      "/kyno/1004b.jpg",
      "/kyno/1004c.jpg",
      "/kyno/1004d.jpg",
      "/kyno/1004e.jpg",
      "/kyno/1004f.jpg",
      "/kyno/1004g.jpg",
      "/kyno/1004h.jpg",
    ],
  },
  {
    id: 5,
    name: "【在庫あり】彩美／SAIBI　センターテーブル ローテーブル 幅750mm（ウォールナット）",
    price: "58000",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: [],
    type: [],
    width: [],
    height: [],
    postage: ["北海道以外の地域", "北海道"],
    delivery: ["玄関前引き渡し", "開梱・設置"],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "https://kyno.jp/images/items/brand/saibi/saibi_ct_category/saibi-tanazumen750.gif",
    ],
    size: [],
    color: [],
    images: [
      "/kyno/1005.jpg",
      "/kyno/1005a.jpg",
      "/kyno/1005b.jpg",
      "/kyno/1005c.jpg",
      "/kyno/1005d.jpg",
      "/kyno/1005e.jpg",
      "/kyno/1005f.jpg",
      "/kyno/1005g.jpg",
    ],
  },
  {
    id: 6,
    name: "リネア（肘置き付）　座面が広くゆったり座れる天然木・無垢のチェア ホワイトオーク 板座",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: [],
    type: [],
    width: [],
    height: [],
    postage: [],
    delivery: ["玄関前引き渡し", "開梱・設置"],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "	https://kyno.jp/pic-labo/linea_arm_zamensize_wo.jpg",
      "https://kyno.jp/pic-labo/lineaarmwo_size.gif",
    ],
    size: [],
    color: [],
    price: "60000",
    images: [
      "/kyno/1006.jpg",
      "/kyno/1006a.jpg",
      "/kyno/1006b.jpg",
      "/kyno/1006c.jpg",
      "/kyno/1006d.jpg",
      "/kyno/1006e.jpg",
    ],
  },
  {
    id: 7,
    name: "凛／RIN　天然木・無垢材使用のダイニングチェア：ハイバック（ウォールナット・ウォルナット - 布座）",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: [],
    type: ["GR-2", "GR-3", "GR-4", "GR-5"],
    width: [],
    height: [],
    postage: [],
    delivery: ["玄関前引き渡し", "開梱・設置"],
    cancellation: [],
    catagory: [],
    length: [],
    sizeImg: [
      "https://kyno.jp/images/items/brand/rin/031000000/nunoza-size.gif",
    ],
    size: [],
    color: [],
    price: "49000",
    images: [
      "/kyno/1007.jpg",
      "/kyno/1007a.jpg",
      "/kyno/1007b.jpg",
      "/kyno/1007c.jpg",
      "/kyno/1007d.jpg",
      "/kyno/1007e.jpg",
      "/kyno/1007f.jpg",
      "/kyno/1007g.jpg",
      "/kyno/1007h.jpg",
    ],
  },
  {
    id: 8,
    name: "Kurt チェア Dow BK_BK",
    backboard: [],
    rail: [],
    panels: [],
    sites: [],
    depth: [],
    type: [],
    width: [],
    height: [],
    postage: ["北海道以外の地域", "北海道"],
    delivery: ["玄関前引き渡し", "開梱・設置"],
    cancellation: ["了解いたしました。"],
    catagory: [],
    length: [],
    sizeImg: ["https://kyno.jp/pic-labo/chair-Dok-size01.jpg"],
    size: [],
    color: [],
    price: "34000",
    images: [
      "/kyno/1008.jpg",
      "/kyno/1008a.jpg",
      "/kyno/1008b.jpg",
      "/kyno/1008c.jpg",
    ],
  },
];

export default productData;
