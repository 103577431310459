import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Privacy() {
  return (
    <>
      <div className="all_center_div">
        <div className="main-container">
          <Header />
          <div className="contactAll">
            <div className="contactLeftBottomTitle">
              <div id="main">
                <h1>個人情報の取り扱いについて</h1>
              </div>
              <p>はじめに</p>
              <p>
                個人情報保護の重要性を認識し、適切に利用し、保護することが社会的責任であると考え、個人情報の保護に努めることをお約束いたします。
              </p>
              <br />
              <p>
                --------------------------------------------------------------------------
              </p>
              <br />
              <p>（個人情報の定義）</p>
              <p>
                個人情報とは、個人に関する情報であり、氏名、生年月日、性別、電話番号、電子メールアドレス、職業、勤務先等、特定の個人を識別し得る情報をいいます。
              </p>
              <br />
              <p>（個人情報の収集・利用）</p>
              <p>
                当社は、以下の目的のため、その範囲内においてのみ、個人情報を収集・利用いたします。
              </p>
              <p>
                当社による個人情報の収集・利用は、お客様の自発的な提供によるものであり、お客様が個人情報を提供された場合は、当社が本方針に則って個人情報を利用することをお客様が許諾したものとします。
              </p>
              <p>・ご注文された当社の商品をお届けするうえで必要な業務</p>
              <p>
                ・新商品の案内など、お客様に有益かつ必要と思われる情報の提供
              </p>
              <p>
                ・業務遂行上で必要となる当社からの問い合わせ、確認、およびサービス向上のための意見収集
              </p>
              <p>・各種のお問い合わせ対応</p>
              <br />
              <p>（個人情報の第三者提供）</p>
              <p>
                当社は、法令に基づく場合等正当な理由によらない限り、事前に本人の同意を得ることなく、個人情報を第三者に開示・提供することはありません。
              </p>
              <br />
              <p>（委託先の監督）</p>
              <p>
                当店は、お客様へ商品やサービスを提供する等の業務遂行上、個人情報の一部を外部の委託先へ提供する場合があります。その場合、業務委託先が適切に個人情報を取り扱うように管理いたします。
              </p>
              <br />
              <p>（個人情報の管理）</p>
              <p>
                当社は、個人情報の漏洩、滅失、毀損等を防止するために、個人情報保護管理責任者を設置し、十分な安全保護に努め、また、個人情報を正確に、また最新なものに保つよう、お預かりした個人情報の適切な管理を行います。
              </p>
              <br />
              <p>（情報内容の照会、修正または削除）</p>
              <p>
                当社は、お客様が当社にご提供いただいた個人情報の照会、修正または削除を希望される場合は、ご本人であることを確認させていただいたうえで、合理的な範囲ですみやかに対応させていただきます。
              </p>
              <br />
              <p>（セキュリティーについて）</p>
              <p>
                SSL暗号通信によりお客様のウェブブラウザとサーバ間の通信がすべて暗号化されるので、お買い物カゴでご記入された内容は安全に送信されます。
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
