import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedLength, setSelectedLength] = useState(null);
  const [selectedCatagory, setSelectedCatagory] = useState(null);
  const [selectedBackboard, setSelectedBackboard] = useState(null);
  const [selectedRail, setSelectedRail] = useState(null);
  const [selectedPanels, setSelectedPanels] = useState(null);
  const [selectedSites, setSelectedSites] = useState(null);
  const [selectedDepth, setSelectedDepth] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedWidth, setSelectedWidth] = useState(null);
  const [selectedHeight, setSelectedHeight] = useState(null);
  const [selectedPostage, setSelectedPostage] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedCancellation, setSelectedCancellation] = useState(null);
  const [quantities, setQuantities] = useState(1);

  const handleAddToCart = () => {
    if (product.size.length > 0 && !selectedSize) {
      alert("サイズを選択してください！");
      return;
    }
    if (product.color.length > 0 && !selectedColor) {
      alert("カラーを選択してください！");
      return;
    }
    if (product.length.length > 0 && !selectedLength) {
      alert("長さを選択してください！");
      return;
    }
    if (product.catagory.length > 0 && !selectedCatagory) {
      alert("カテゴリーを選択してください！");
      return;
    }

    if (product.backboard.length > 0 && !selectedBackboard) {
      alert("バックボードを選択してください！");
      return;
    }
    if (product.rail.length > 0 && !selectedRail) {
      alert("レールを選択してください！");
      return;
    }
    if (product.panels.length > 0 && !selectedPanels) {
      alert("パネルを選択してください！");
      return;
    }
    if (product.sites.length > 0 && !selectedSites) {
      alert("サイトを選択してください！");
      return;
    }
    if (product.depth.length > 0 && !selectedDepth) {
      alert("奥行を選択してください！");
      return;
    }
    if (product.type.length > 0 && !selectedType) {
      alert("タイプを選択してください！");
      return;
    }
    if (product.width.length > 0 && !selectedWidth) {
      alert("幅を選択してください！");
      return;
    }
    if (product.height.length > 0 && !selectedHeight) {
      alert("高さを選択してください！");
      return;
    }
    if (product.postage.length > 0 && !selectedPostage) {
      alert("送料を選択してください！");
      return;
    }
    if (product.delivery.length > 0 && !selectedDelivery) {
      alert("配送方法を選択してください！");
      return;
    }
    if (product.cancellation.length > 0 && !selectedCancellation) {
      alert("キャンセルポリシーを選択してください！");
      return;
    }

    const options = {
      size: selectedSize || null,
      color: selectedColor || null,
      length: selectedLength || null,
      category: selectedCatagory || null,
      backboard: selectedBackboard || null,
      rail: selectedRail || null,
      panels: selectedPanels || null,
      sites: selectedSites || null,
      depth: selectedDepth || null,
      type: selectedType || null,
      width: selectedWidth || null,
      height: selectedHeight || null,
      postage: selectedPostage || null,
      delivery: selectedDelivery || null,
      cancellation: selectedCancellation || null,
    };

    addToCart(
      product.id,
      quantities,
      options.size,
      options.color,
      options.length,
      options.category,
      options.backboard,
      options.rail,
      options.panels,
      options.sites,
      options.depth,
      options.type,
      options.width,
      options.height,
      options.postage,
      options.delivery,
      options.cancellation
    );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };

  if (!product) {
    return <div>商品が見つかりません</div>;
  }

  return (
    <>
      <div className="all_center_div">
        <div className="main-container">
          <Header />
          <div className="shopDiv">
            <h4>バリエーション</h4>
            <p className="size_p_div">
              ※寸法の表記はmmです。　※規格や仕様は予告無しに変更されることがりますのでご了承ください。
            </p>
            {product.sizeImg.map((img, index) => (
              <div className="img_img_div" key={index}>
                <img alt="" src={img} />
              </div>
            ))}
            <div className="product-detail">
              <div className="product-images">
                <div className="carousel">
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${product.name}`}
                      style={{
                        display: index === currentIndex ? "block" : "none",
                      }}
                    />
                  ))}
                </div>
                <div className="carousel-buttons">
                  {product.images.map((image, index) => (
                    <button key={index} onClick={() => setCurrentIndex(index)}>
                      <img src={image} alt={`${product.name}`} />
                    </button>
                  ))}
                </div>
              </div>
              <div className="product-info">
                <h3 className="sldo__name">
                  <strong style={{ color: "#000" }}>{product.name}</strong>
                </h3>
                <div className="prices sale">
                  <p className="box__price">
                    <span className="price">
                      <span className="selling_price_str">価格:</span>¥
                      {product.price}(税込)
                    </span>
                  </p>
                </div>
                <div className="is_exist_select">
                  {product.size.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">サイズ</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedSize}
                            onChange={(e) => setSelectedSize(e.target.value)}
                            className={selectedSize ? "selected" : ""}
                          >
                            <option value="">選択してください</option>
                            {product.size.map((size, index) => (
                              <option key={index} value={size}>
                                {size}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.color.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">カラー</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedColor}
                            onChange={(e) => setSelectedColor(e.target.value)}
                            className={selectedColor ? "selected" : ""}
                          >
                            <option value="">色を選択してください</option>
                            {product.color.map((color, index) => (
                              <option key={index} value={color}>
                                {color}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.catagory.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">カテゴリ</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedCatagory}
                            onChange={(e) =>
                              setSelectedCatagory(e.target.value)
                            }
                            className={selectedCatagory ? "selected" : ""}
                          >
                            <option value="">種類を選択してください</option>
                            {product.catagory.map((catagory, index) => (
                              <option key={index} value={catagory}>
                                {catagory}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.length.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">長さ</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedLength}
                            onChange={(e) => setSelectedLength(e.target.value)}
                            className={selectedLength ? "selected" : ""}
                          >
                            <option value="">長さを選択してください</option>
                            {product.length.map((length, index) => (
                              <option key={index} value={length}>
                                {length}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.backboard.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">
                          バックボード
                        </div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedBackboard}
                            onChange={(e) =>
                              setSelectedBackboard(e.target.value)
                            }
                            className={selectedBackboard ? "selected" : ""}
                          >
                            <option value="">
                              バックボードを選択してください
                            </option>
                            {product.backboard.map((backboard, index) => (
                              <option key={index} value={backboard}>
                                {backboard}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.rail.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">レール</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedRail}
                            onChange={(e) => setSelectedRail(e.target.value)}
                            className={selectedRail ? "selected" : ""}
                          >
                            <option value="">レールを選択してください</option>
                            {product.rail.map((rail, index) => (
                              <option key={index} value={rail}>
                                {rail}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.panels.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">パネル</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedPanels}
                            onChange={(e) => setSelectedPanels(e.target.value)}
                            className={selectedPanels ? "selected" : ""}
                          >
                            <option value="">パネルを選択してください</option>
                            {product.panels.map((panels, index) => (
                              <option key={index} value={panels}>
                                {panels}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.sites.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">サイト</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedSites}
                            onChange={(e) => setSelectedSites(e.target.value)}
                            className={selectedSites ? "selected" : ""}
                          >
                            <option value="">サイトを選択してください</option>
                            {product.sites.map((sites, index) => (
                              <option key={index} value={sites}>
                                {sites}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.depth.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">奥行</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedDepth}
                            onChange={(e) => setSelectedDepth(e.target.value)}
                            className={selectedDepth ? "selected" : ""}
                          >
                            <option value="">奥行を選択してください</option>
                            {product.depth.map((depth, index) => (
                              <option key={index} value={depth}>
                                {depth}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.type.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">タイプ</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                            className={selectedType ? "selected" : ""}
                          >
                            <option value="">タイプを選択してください</option>
                            {product.type.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.width.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">幅</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedWidth}
                            onChange={(e) => setSelectedWidth(e.target.value)}
                            className={selectedWidth ? "selected" : ""}
                          >
                            <option value="">幅を選択してください</option>
                            {product.width.map((width, index) => (
                              <option key={index} value={width}>
                                {width}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.height.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">高さ</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedHeight}
                            onChange={(e) => setSelectedHeight(e.target.value)}
                            className={selectedHeight ? "selected" : ""}
                          >
                            <option value="">高さを選択してください</option>
                            {product.height.map((height, index) => (
                              <option key={index} value={height}>
                                {height}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.postage.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">送料</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedPostage}
                            onChange={(e) => setSelectedPostage(e.target.value)}
                            className={selectedPostage ? "selected" : ""}
                          >
                            <option value="">送料を選択してください</option>
                            {product.postage.map((postage, index) => (
                              <option key={index} value={postage}>
                                {postage}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.delivery.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">配送方法</div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedDelivery}
                            onChange={(e) =>
                              setSelectedDelivery(e.target.value)
                            }
                            className={selectedDelivery ? "selected" : ""}
                          >
                            <option value="">配送方法を選択してください</option>
                            {product.delivery.map((delivery, index) => (
                              <option key={index} value={delivery}>
                                {delivery}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {product.cancellation.length > 0 && (
                    <>
                      <div>
                        <div className="rio-product-option-title">
                          キャンセルポリシー
                        </div>
                        <div className="sldo__size_div">
                          <select
                            value={selectedCancellation}
                            onChange={(e) =>
                              setSelectedCancellation(e.target.value)
                            }
                            className={selectedCancellation ? "selected" : ""}
                          >
                            <option value="">
                              キャンセルポリシーを選択してください
                            </option>
                            {product.cancellation.map((cancellation, index) => (
                              <option key={index} value={cancellation}>
                                {cancellation}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <span style={{ color: "#1a33a9", fontWeight: "700" }}>
                  数量：&nbsp;
                  <input
                    type="number"
                    value={quantities}
                    min="1"
                    onChange={(e) =>
                      setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                    }
                    className="quantity-input"
                  />
                  &nbsp; 台
                </span>
                <br />
                <div className="btnDiv" onClick={handleAddToCart}>
                  {/* <button className="add-to-cart" onClick={handleAddToCart}>
                  カートに追加
                </button> */}
                  <img
                    alt=""
                    style={{ width: "100%" }}
                    src="https://kyno.jp/hpgen/HPB/theme/img/btn_cartl.gif"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
