import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate, Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("チェックアウトに進む前にカートに商品を追加してください。");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity || price * 1;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price);
    return price * quantity || price * 1;
  };

  const handleInputChange = (
    id,
    event,
    size,
    color,
    length,
    head,
    category
  ) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(
        id,
        newQuantity,
        size || undefined,
        color || undefined,
        length || undefined,
        head || undefined,
        category || undefined
      );
    }
  };

  return (
    <>
      <div className="all_center_div">
        <div className="main-container">
          <div>
            <img
              width="326"
              height="82"
              alt=""
              src="https://kyno.jp/pic-labo/logo1200300.jpg"
            />
          </div>
          <div className="cart-page">
            <h3 className="title1_no2">ショッピングカート</h3>
            <p className="goBackShop_p">
              現在のご注文内容は下記のとおりです。
              <br />
              「購入手続きへ進む」ボタンをクリックするとお支払い方法・お届け先等を入力するページに進みます。{" "}
            </p>
            <p className="left">
              <Link type="button" to="/" className="back_to_shop_button1">
                買い物を続ける
              </Link>
            </p>
            <h4 className="SPS-cart-item">ご注文内容</h4>

            <div className="cart-total">
              <div className="cart-items">
                {cartItems.length === 0 ? (
                  <h3 style={{ color: "black", textAlign: "center" }}>
                    カートは空です！
                  </h3>
                ) : (
                  <StyledTableContainer component={Paper}>
                    <Table aria-label="shopping cart table">
                      <TableHead style={{ height: "50px" }}>
                        <TableRow>
                          <TableCell align="center">画像</TableCell>
                          <TableCell align="center">品名</TableCell>
                          <TableCell align="center">価格</TableCell>
                          <TableCell align="center">数量</TableCell>
                          <TableCell align="center">小計</TableCell>
                          <TableCell align="center">削除</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartItems.map((item, index) => {
                          const product = getProductById(item.id);
                          const productOptions = {
                            size: item.size,
                            color: item.color,
                            length: item.length,
                            category: item.category,
                            backboard: item.backboard,
                            rail: item.rail,
                            panels: item.panels,
                            sites: item.sites,
                            depth: item.depth,
                            type: item.type,
                            width: item.width,
                            height: item.height,
                            postage: item.postage,
                            delivery: item.delivery,
                            cancellation: item.cancellation,
                          };
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  align="left"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "9%",
                                  }}
                                >
                                  <StyledImage
                                    src={product.images[0]}
                                    alt="Product"
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    verticalAlign: "top",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                    }}
                                  >
                                    {product.name}
                                    <br />
                                    {item.size && (
                                      <div className="isExist">
                                        サイズ: {item.size}
                                      </div>
                                    )}
                                    {item.color && (
                                      <div className="isExist">
                                        カラー: {item.color}
                                      </div>
                                    )}
                                    {item.catagory && (
                                      <div className="isExist">
                                        カテゴリー: {item.catagory}
                                      </div>
                                    )}
                                    {item.length && (
                                      <div className="isExist">
                                        長さ: {item.length}
                                      </div>
                                    )}
                                    {item.backboard && (
                                      <div className="isExist">
                                        バックボード: {item.backboard}
                                      </div>
                                    )}
                                    {item.rail && (
                                      <div className="isExist">
                                        レール: {item.rail}
                                      </div>
                                    )}
                                    {item.panels && (
                                      <div className="isExist">
                                        パネル: {item.panels}
                                      </div>
                                    )}
                                    {item.sites && (
                                      <div className="isExist">
                                        サイト: {item.sites}
                                      </div>
                                    )}
                                    {item.depth && (
                                      <div className="isExist">
                                        奥行: {item.depth}
                                      </div>
                                    )}
                                    {item.type && (
                                      <div className="isExist">
                                        タイプ: {item.type}
                                      </div>
                                    )}
                                    {item.width && (
                                      <div className="isExist">
                                        幅: {item.width}
                                      </div>
                                    )}
                                    {item.height && (
                                      <div className="isExist">
                                        高さ: {item.height}
                                      </div>
                                    )}
                                    {item.postage && (
                                      <div className="isExist">
                                        送料: {item.postage}
                                      </div>
                                    )}
                                    {item.delivery && (
                                      <div className="isExist">
                                        配送方法: {item.delivery}
                                      </div>
                                    )}
                                    {item.cancellation && (
                                      <div className="isExist">
                                        キャンセルポリシー: {item.cancellation}
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "9%",
                                  }}
                                >
                                  {product.price}円
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <input
                                    type="number"
                                    value={item.quantity || 1}
                                    className="cartInput"
                                    onChange={(e) =>
                                      handleInputChange(
                                        item.id,
                                        e,
                                        ...Object.values(productOptions)
                                      )
                                    }
                                    min="0"
                                  />
                                  台
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "end",
                                    width: "12%",
                                  }}
                                >
                                  {calculateItemTotal(item.id, item.quantity)}円
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    verticalAlign: "middle",
                                    width: "10%",
                                    textAlign: "center",
                                  }}
                                >
                                  <a
                                    onClick={() =>
                                      removeFromCart(
                                        item.id,
                                        ...Object.values(productOptions)
                                      )
                                    }
                                    className="remove__goods"
                                  >
                                    削除
                                  </a>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                        <TableRow style={{ width: "100%" }}>
                          <TableCell colSpan="4" style={{ textAlign: "end" }}>
                            商品合計
                          </TableCell>
                          <TableCell colSpan="2" style={{ textAlign: "end" }}>
                            {calculateTotal()}円
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                )}
              </div>
            </div>

            <p className="check_btn_right">
              <Link
                type="button"
                to="/checkout"
                className="back_to_shop_button1"
                onClick={toCheckOut}
              >
                購入手続きへ進む
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
