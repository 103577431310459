import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fakefirstName, setFakeFirstName] = useState("");
  const [fakelastName, setFakeLastName] = useState("");
  const [code, setCode] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [buildName, setBuildName] = useState("");
  const [tel, setTel] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [emailAdd, setEmailAdd] = useState("");
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const StyledTableContainer = styled(TableContainer)({
    minWidth: 650,
  });

  const StyledImage = styled("img")({
    width: 112,
    height: 124,
    objectFit: "cover",
  });

  const handlePlaceOrder = () => {
    const newErrors = {};
    const requiredFields = document.querySelectorAll(
      "tr:has(span.sps-required) input, tr:has(span.sps-required) select"
    );
    requiredFields.forEach((input) => {
      if (!input.value) {
        newErrors[input.name] = "该项目必须填写";
        input.style.border = "2px solid red";
      } else {
        input.style.border = "";
      }
    });

    const paymentMethod = document.querySelector(
      'input[name="PAYTYPE"]:checked'
    );
    if (!paymentMethod) {
      alert("支払い方法を1つ選択してください!");
      return;
    }

    // Check for "信用卡支付"
    if (paymentMethod.value === "4") {
      const cardInfo = document.querySelector('input[name="card_info_set"]');
      if (!cardInfo.value) {
        alert("クレジットカード情報を入力してください!");
        cardInfo.style.border = "2px solid red";
        return;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      alert("すべての必須項目を記入し、支払い方法を1つ選択してください!");
    } else {
      alert("注文が完了しました！");
      clearCart();
      navigate("/paysuccess");
    }
  };
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity || price * 1;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price);
    return price * quantity || price * 1;
  };

  return (
    <>
      <div className="all_center_div">
        <div className="main-container">
          <div>
            <img
              width="326"
              height="82"
              alt=""
              src="https://kyno.jp/pic-labo/logo1200300.jpg"
            />
          </div>
          {/* 商品 */}
          <div className="cart-page">
            <h3 className="title1_no2">ショッピングカート</h3>
            <p className="goBackShop_p">
              現在のご注文内容は下記のとおりです。
              <br />
              「購入手続きへ進む」ボタンをクリックするとお支払い方法・お届け先等を入力するページに進みます。
            </p>
            <p className="left">
              <Link type="button" to="/" className="back_to_shop_button1">
                買い物を続ける
              </Link>
            </p>
            <h4 className="SPS-cart-item">ご注文内容</h4>

            <div className="cart-total">
              <div className="cart-items">
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead style={{ height: "50px" }}>
                      <TableRow>
                        <TableCell align="center">画像</TableCell>
                        <TableCell align="center">品名</TableCell>
                        <TableCell align="center">価格</TableCell>
                        <TableCell align="center">数量</TableCell>
                        <TableCell align="center">小計</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell
                                component="td"
                                scope="row"
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                  width: "9%",
                                }}
                              >
                                <StyledImage
                                  src={product.images[0]}
                                  alt="Product"
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "top",
                                }}
                              >
                                <div
                                  style={{
                                    display: "grid",
                                    width: "100%",
                                  }}
                                >
                                  {product.name}
                                  <br />
                                  {item.size && (
                                    <div className="isExist">
                                      サイズ: {item.size}
                                    </div>
                                  )}
                                  {item.color && (
                                    <div className="isExist">
                                      カラー: {item.color}
                                    </div>
                                  )}
                                  {item.catagory && (
                                    <div className="isExist">
                                      カテゴリー: {item.catagory}
                                    </div>
                                  )}
                                  {item.length && (
                                    <div className="isExist">
                                      長さ: {item.length}
                                    </div>
                                  )}
                                  {item.backboard && (
                                    <div className="isExist">
                                      バックボード: {item.backboard}
                                    </div>
                                  )}
                                  {item.rail && (
                                    <div className="isExist">
                                      レール: {item.rail}
                                    </div>
                                  )}
                                  {item.panels && (
                                    <div className="isExist">
                                      パネル: {item.panels}
                                    </div>
                                  )}
                                  {item.sites && (
                                    <div className="isExist">
                                      サイト: {item.sites}
                                    </div>
                                  )}
                                  {item.depth && (
                                    <div className="isExist">
                                      奥行: {item.depth}
                                    </div>
                                  )}
                                  {item.type && (
                                    <div className="isExist">
                                      タイプ: {item.type}
                                    </div>
                                  )}
                                  {item.width && (
                                    <div className="isExist">
                                      幅: {item.width}
                                    </div>
                                  )}
                                  {item.height && (
                                    <div className="isExist">
                                      高さ: {item.height}
                                    </div>
                                  )}
                                  {item.postage && (
                                    <div className="isExist">
                                      送料: {item.postage}
                                    </div>
                                  )}
                                  {item.delivery && (
                                    <div className="isExist">
                                      配送方法: {item.delivery}
                                    </div>
                                  )}
                                  {item.cancellation && (
                                    <div className="isExist">
                                      キャンセルポリシー: {item.cancellation}
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                  width: "9%",
                                }}
                              >
                                {product.price}円
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.quantity || 1}台
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "end",
                                  width: "12%",
                                }}
                              >
                                {calculateItemTotal(item.id, item.quantity)}円
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      <TableRow style={{ width: "100%" }}>
                        <TableCell colspan="4" style={{ textAlign: "end" }}>
                          商品合計
                        </TableCell>
                        <TableCell colspan="2" style={{ textAlign: "end" }}>
                          {calculateTotal()}円
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </div>
            </div>
          </div>
          {/* 登记信息 */}
          <h4 className="SPS-regi-purchaser">購入者情報</h4>
          <p className="write_left">
            Amazonアカウントでログインすると、Amazon.co.jpに登録された情報を利用してご注文いただけます。
          </p>
          <div className="bordlayoutp3 center">
            <table
              className="sps-inputTable"
              width="95%"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <tbody>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    お名前
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div></div>
                    <div style={{ float: "left" }}>
                      <input
                        type="text"
                        name="LNAME"
                        id="spsLname"
                        className="validate[reqMsg[lName]] amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "active" }}
                        maxlength="32"
                        size="15"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="例）山田"
                        required=""
                        data-prompt-position="inline"
                      />
                    </div>
                    <div style={{ float: "left" }}>&nbsp;</div>
                    <div style={{ float: "left" }}>
                      <input
                        type="text"
                        name="FNAME"
                        id="spsFname"
                        className="validate[reqMsg[fName]] amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "active" }}
                        maxlength="32"
                        size="15"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        placeholder="例）太郎"
                        data-prompt-position="inline"
                      />
                    </div>
                    <div
                      className="name_target formError"
                      style={{ clear: "both" }}
                    ></div>
                  </td>
                </tr>

                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    お名前(かな)
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div style={{ float: "left" }}></div>
                    <div style={{ float: "left" }}>
                      <input
                        type="text"
                        className="amazon-param  validate[chkMsg[lNameKana]] kata-to-kana blockSubmit"
                        name="LNAME_KANA"
                        id="spsLnameKana"
                        style={{ height: "17px", imeMode: "active" }}
                        maxlength="32"
                        size="15"
                        value={fakefirstName}
                        onChange={(e) => setFakeFirstName(e.target.value)}
                        placeholder="例）やまだ"
                        data-prompt-position="inline"
                      />
                    </div>
                    <div style={{ float: "left" }}>&nbsp;</div>
                    <div style={{ float: "left" }}>
                      <input
                        type="text"
                        className="amazon-param  validate[chkMsg[fNameKana]] kata-to-kana blockSubmit"
                        name="FNAME_KANA"
                        id="spsFnameKana"
                        style={{ height: "17px", imeMode: "active" }}
                        maxlength="32"
                        size="15"
                        value={fakelastName}
                        onChange={(e) => setFakeLastName(e.target.value)}
                        placeholder="例）たろう"
                        data-prompt-position="inline"
                      />
                    </div>
                    <div
                      className="name_target formError"
                      style={{ clear: "both" }}
                    ></div>
                  </td>
                </tr>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    郵便番号
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        size="12"
                        className="validate[reqMsg[zip]] zip_size amazon-param blockSubmit"
                        style={{ height: "17px", width: "35%" }}
                        name="ZIP99"
                        maxlength="8"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="例）1000000"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>

                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    都道府県
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <select
                        name="PREF"
                        className="validate[reqMsg[pref]] seekzip_pref amazon-param blockSubmit"
                        data-prompt-position="inline"
                        value={prefecture}
                        onChange={(e) => setPrefecture(e.target.value)}
                      >
                        <option value="">選択してください</option>
                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    市区郡
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        size="36"
                        className="validate[reqMsg[addr1]] seekzip_addr1 amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "active" }}
                        name="ADDR1"
                        maxlength="64"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="例）新宿区"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    町村字番地
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        size="36"
                        className="validate[reqMsg[addr2]] seekzip_addr2 amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "active" }}
                        name="ADDR2"
                        maxlength="64"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="例）西新宿1-1-1"
                        onkeydown="toggleCheckAddr(false);"
                        data-prompt-position="inline"
                      />
                      <label
                        id="check_addr"
                        className="has-error"
                        style={{ display: "none" }}
                      >
                        番地が抜けていないかご確認ください。
                      </label>
                    </div>
                  </td>
                </tr>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    建物名（部屋番号）
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        size="36"
                        className="amazon-param validate[chkMsg[addr3]] blockSubmit"
                        style={{ height: "17px", imeMode: "active" }}
                        name="ADDR3"
                        maxlength="64"
                        value={buildName}
                        onChange={(e) => setBuildName(e.target.value)}
                        placeholder="例）西新宿マンション204"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>

                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    お電話番号
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        size="24"
                        className="validate[reqMsg[tel]] amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "disabled" }}
                        name="TEL99"
                        maxlength="17"
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                        placeholder="例）0300000000"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>

                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    FAX番号
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        size="24"
                        className="validate[chkMsg[fax]] amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "disabled" }}
                        name="FAX99"
                        maxlength="17"
                        value={fax}
                        onChange={(e) => setFax(e.target.value)}
                        placeholder="例）0300000000"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    メールアドレス
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        className="validate[reqMsg[email]] zen-to-han amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "disabled" }}
                        name="MAIL"
                        id="spsMail"
                        maxlength="80"
                        size="36"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="例）clear@thinkless.live"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>
                <tr align="center">
                  <td align="left" className="backcolor1 sps-inputItem">
                    メールアドレス(確認)
                    <span className="sps-required cart_float_right">必須</span>
                  </td>
                  <td align="left" className="backcolor2 sps-inputContent">
                    <div>
                      <input
                        type="text"
                        className="validate[reqMsg[email_conf]] zen-to-han amazon-param blockSubmit"
                        style={{ height: "17px", imeMode: "disabled" }}
                        name="MAIL_CONF"
                        id="spsMailConf"
                        maxlength="80"
                        size="36"
                        value={emailAdd}
                        onChange={(e) => setEmailAdd(e.target.value)}
                        placeholder="例）clear@thinkless.live"
                        data-prompt-position="inline"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 支付方式 */}
          <h4 className="SPS-regi-purchaser">お支払い方法選択</h4>
          <div className="bordlayoutp3 center form_group" id="selectPayment">
            <p className="left" style={{ width: "95%", margin: "0 auto" }}>
              <span id="paymentSelectStr"></span>
            </p>
            <table
              width="95%"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              className="border"
            >
              <tbody>
                <tr id="card_info_ecs">
                  <td className="backcolor1">
                    <input
                      type="radio"
                      name="PAYTYPE"
                      value="4"
                      id="PAYTYPE_4"
                      style={{ verticalAlign: "-2px" }}
                      className="validate[reqMsg[payment_radio]] pm-radio amazon-param"
                      data-prompt-target="paymentSelectStr"
                      data-prompt-position="inline"
                      data-gtm-form-interact-field-id="1"
                    />
                    <label htmlFor="PAYTYPE_4">
                      <strong>信用卡支付</strong>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className="side backcolor2"
                    id="PAYTYPE_4_DETAIL"
                  >
                    <div className="paytypewrap">
                      <div id="card_info">
                        <ul>
                          <li
                            className="pmAdditionalErr"
                            style={{ display: "none" }}
                          >
                            <font color="#FF0000">
                              ●カード情報を入力してください。
                            </font>
                          </li>
                          <li style={{ listStyle: "none", marginLeft: "-4%" }}>
                            <input
                              type="txt"
                              name="card_info_set"
                              className="pmAdditionalCheck off"
                              placeholder="输入您的卡信息"
                            />
                          </li>
                        </ul>
                        <p>
                          如果您使用的信用卡不是您名下的信用卡，您可能会在订购后被要求验证您的身份。
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="backcolor1">
                    <input
                      type="radio"
                      name="PAYTYPE"
                      value="2"
                      id="PAYTYPE_2"
                      style={{ verticalAlign: "-2px" }}
                      className="validate[reqMsg[payment_radio]] pm-radio amazon-param"
                      data-prompt-target="paymentSelectStr"
                      data-prompt-position="inline"
                      data-gtm-form-interact-field-id="2"
                    />
                    <label htmlFor="PAYTYPE_2">
                      <strong>银行转账</strong>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className="side backcolor2"
                    id="PAYTYPE_2_DETAIL"
                  >
                    <div className="paytypewrap">
                      <p>
                        <font>
                          【转账账户】 西日本城市银行大川分行 普通1233865 Shizen
                          Kobo （Ka）
                        </font>
                      </p>
                      <p>
                        请转账到本店指定的账户。转账费用由我们承担，因此请从发票金额中扣除费用后转账。
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="backcolor1">
                    <input
                      type="radio"
                      name="PAYTYPE"
                      value="1"
                      id="PAYTYPE_1"
                      style={{ verticalAlign: "-2px" }}
                      className="validate[reqMsg[payment_radio]] pm-radio amazon-param"
                      data-prompt-target="paymentSelectStr"
                      data-prompt-position="inline"
                      data-gtm-form-interact-field-id="3"
                    />
                    <label htmlFor="PAYTYPE_1">
                      <strong>货到付款</strong>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className="side backcolor2"
                    id="PAYTYPE_1_DETAIL"
                  >
                    <div className="paytypewrap">
                      <p>您可以选择在商品送达时支付货款。</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="backcolor1">
                    <input
                      type="radio"
                      name="PAYTYPE"
                      value="15"
                      id="PAYTYPE_15"
                      style={{ verticalAlign: "-2px" }}
                      className="validate[reqMsg[payment_radio]] pm-radio amazon-param"
                      data-prompt-target="paymentSelectStr"
                      data-prompt-position="inline"
                    />
                    <label htmlFor="PAYTYPE_15">
                      <strong>亚马逊支付</strong>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className="side backcolor2"
                    id="PAYTYPE_15_DETAIL"
                  >
                    <div className="paytypewrap">
                      <div align="center">
                        <img
                          src="https://cart4.shopserve.jp/vol1blog/k/kyno.rs.shopserve.jp/docs/pic-labo/amazonpay.png"
                          alt="Amazon Pay"
                        />
                      </div>
                      <p>
                        您可以使用在 Amazon 账户中注册的信用卡信息进行付款。
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <input
              type="button"
              className="validation_pcsubmit_btn_regi_next"
              onClick={handlePlaceOrder}
              value="提交"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
