import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <p className="footer__shop-info__about">
          <font _mstmutation="1">
            <Link to="/" _mstmutation="1" onClick={toTop}>
              HOME
            </Link>
            <Link to="/contact" _mstmutation="1" onClick={toTop}>
              お問い合わせ
            </Link>
            <Link to="/privacy" _mstmutation="1" onClick={toTop}>
              個人情報の取り扱いについて
            </Link>
            <Link to="/particular" _mstmutation="1" onClick={toTop}>
              特定商取引法に関する表示
            </Link>
            <Link to="/" _mstmutation="1" onClick={toTop}>
              © 2010 TANYA UUOO LLC
            </Link>
          </font>
        </p>
      </div>
    </>
  );
}

export default Footer;
