import "../css/header.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="head_top_img">
          <img
            width="240"
            height="60"
            alt=""
            src="https://kyno.jp/pic-labo/logo1200300.jpg"
          />
        </div>
        <div id="gnav">
          <nav>
            <ul>
              <li>
                <Link to="/" className="homeToA" onClick={toTop}>
                  ホーム
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="homeToA" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="/cartpage" onClick={toTop}>
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/windows/50/FFFFFF/shopping-bag.png"
                    alt="shopping-bag"
                  />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
