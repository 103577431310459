import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!name) newErrors.name = "お名前を入力してください。";
    if (!mail) newErrors.mail = "メールアドレスを入力してください。";
    if (!message) newErrors.message = "お問い合わせ内容を入力してください。";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("必須の情報を入力してください");
      return;
    } else {
      alert("送信が成功しました！");
      setName("");
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <>
      <div className="all_center_div">
        <div className="main-container">
          <Header />
          <div className="contactAll">
            <div className="contactLeftBottomTitle">
              <div id="main">
                <h1>お問い合わせ</h1>
              </div>
              <p>
                当店へのご質問などございましたらお気軽にお問い合せください。
              </p>
              <br />
              <p>
                また、詳細な内容の打ち合わせに
                <br />
                <b>図面や画像などのデータを送信できる方</b>は<br />
                <br />
                <Link
                  to="mailto:clear@thinkless.live"
                  style={{ color: "#2675c1" }}
                >
                  clear@thinkless.live
                </Link>
                にメールにて送信ください。
              </p>
              <br />
              <p>
                ※こちらのお問い合わせフォームからは画像などの添付ができません。
              </p>
              <div className="layoutp3 center">
                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                  <tbody>
                    <tr>
                      <th>
                        お名前 <span className="caution">*</span>
                      </th>
                      <td>
                        <input
                          type="text"
                          className="text1"
                          name="Form01"
                          size="40"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>電話番号</th>
                      <td>
                        <input
                          type="text"
                          className="text1"
                          size="40"
                          name="Form02"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>
                        メールアドレス <span className="caution">*</span>
                      </th>
                      <td>
                        <input
                          name="YourMail1"
                          type="text"
                          size="40"
                          className="text1"
                          required
                          value={mail}
                          onChange={(e) => setMail(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        お問い合わせ内容 <span className="caution">*</span>
                      </th>
                      <td>
                        <textarea
                          name="Naiyo"
                          cols="42"
                          rows="10"
                          className="text1"
                          value={message}
                          required
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <input
                  type="button"
                  name="Submit"
                  value="送信してください。"
                  onClick={contactSubmit}
                  className="contact_btn"
                />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
