import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="add_main_div">
        <div className="main-container">
          <Header />
          <div className="mainBox">
            <div className="twoDiv">
              <img
                alt=""
                src="https://kyno.jp/pic-labo/sp-top-slider-banner-diningtable.jpg"
              />
              <img
                alt=""
                src="https://kyno.jp/pic-labo/sp-top-slider-banner.jpg"
              />
            </div>
            <p className="nature_p">
              <strong>自然工房Instagram</strong>
            </p>
            <div className="product-grid">
              {productData.map((brand) => (
                <div className="product-category" key={brand.id}>
                  <div className="image-container">
                    <Link
                      to={`/shopDetails/${brand.id}`}
                      onClick={handleNavigate}
                    >
                      <img alt="" src={brand.images[0]} />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <h2 className="title_bg_black">新着お知らせ</h2>
            <div className="new_box">
              <div className="new_box_child">
                <div>
                  <img
                    width="250"
                    height="250"
                    src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-olivia.jpg"
                    alt="Olivia"
                    align="baseline"
                  />
                </div>
                <div>
                  <img
                    width="250"
                    height="250"
                    src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner.jpg"
                    alt="Kurt/クルト"
                    align="baseline"
                  />
                </div>
              </div>
              <div className="new_box_child">
                <strong>【新商品を追加いたしました。】</strong>
                <br />
                バイヤーズセレクト商品のKurtシリーズ、
                <br />
                Oliviaシリーズに新商品を追加いたしました。
              </div>
            </div>
            <h2 className="title_bg_black">アイテムカテゴリー</h2>
            <div className="contents20">
              <table className="item_iconbox" style={{ marginTop: "30px" }}>
                <tbody>
                  <tr>
                    <th>
                      <img
                        width="125"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon001.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="125"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon002.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="138"
                        height="71"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon003.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="38"
                        height="70"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon004.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="130"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon005.png"
                        align="baseline"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <a href="/">
                        テレビボード・
                        <br />
                        テレビ台
                      </a>
                    </th>
                    <th>
                      <a href="/">
                        小型テーブル・
                        <br />
                        ローテーブル
                      </a>
                    </th>
                    <th>
                      <a href="/">
                        ダイニング
                        <br />
                        テーブル
                      </a>
                    </th>
                    <th>
                      <a href="/">
                        チェア・
                        <br />
                        ベンチ
                      </a>
                    </th>
                    <th>
                      <a href="/">システムデスク</a>
                    </th>
                  </tr>
                </tbody>
              </table>
              <table className="item_iconbox" style={{ marginTop: "30px" }}>
                <tbody>
                  <tr>
                    <th>
                      <img
                        width="125"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon006.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="125"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon007.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="125"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon008.png"
                        align="baseline"
                      />
                    </th>
                    <th>
                      <img
                        width="125"
                        height="55"
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/item_icon009.png"
                        align="baseline"
                      />
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>
                      <a href="/">
                        タンス・
                        <br />
                        チェスト・収納
                      </a>
                    </th>
                    <th>
                      <a href="/">
                        リビング収納
                        <br />
                        リビングボード
                      </a>
                    </th>
                    <th>
                      <a href="/">
                        レンジボード
                        <br />
                        ・食器棚
                      </a>
                    </th>
                    <th>
                      <a href="/">ソファ</a>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 className="title_bg_black">シリーズ</h2>

            <div className="contents20">
              <div className="bnr_box">
                <ul className="bnr_box_ul20">
                  <li>
                    <div className="bnr_box_base">
                      <img
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/series-banner-fuga.jpg"
                        align="baseline"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="bnr_box_base">
                      <img
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/series-banner-rin.jpg"
                        align="baseline"
                      />
                    </div>
                  </li>
                </ul>
                <ul className="bnr_box_ul20">
                  <li>
                    <div className="bnr_box_base">
                      <img
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/series-banner-saibi.jpg"
                        align="baseline"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="bnr_box_base">
                      <img
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/series-banner-desk.jpg"
                        align="baseline"
                      />
                    </div>
                  </li>
                </ul>
                <ul>
                  <li>
                    <div className="bnr_box_base">
                      <img
                        alt=""
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/series_bnr-05.jpg"
                        align="baseline"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="bnr_box_base">
                      <img
                        src="https://kyno.jp/pic-labo/topimg01.jpg"
                        alt="在庫商品"
                        align="baseline"
                        border="0"
                        hspace="0"
                        vspace="0"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div></div>
              <div className="border_title">
                「風雅」「凛」「彩美」「システムデスク」シリーズの特徴
              </div>
              <div className="textcenter">
                <img
                  alt=""
                  src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/next100-bnr.png"
                  align="baseline"
                />
              </div>
              <p>
                1928(昭和3)年に創業以来、まもなく100周年を迎える無垢材家具専門店。
                <br />
                熟練の技術で丁寧に誠実に次の100年に向けてメイド・イン・ジャパンの品質の高い家具を作り続けていきます。
              </p>
              <ul className="explain_base_box3">
                <li>
                  <div className="bnr_box_base2">
                    <a href="https://kyno.jp/smp/freepage_detail.php?cid=4147&amp;fid=5&amp;pcflg=1">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-01.jpg"
                        alt="全国送料無料"
                        border="0"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="bnr_box_base2">
                    <a href="https://kyno.jp/smp/freepage_detail.php?cid=4147&amp;fid=4&amp;pcflg=1">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-02.jpg"
                        alt="メーカー直売価格"
                        border="0"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="bnr_box_base2">
                    <a href="https://kyno.jp/smp/freepage_detail.php?cid=4147&amp;fid=6&amp;pcflg=1">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-03.jpg"
                        alt="梱包・組立設置無料"
                        border="0"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="bnr_box_base2">
                    <a href="https://kyno.jp/smp/freepage_detail.php?cid=5936&amp;fid=290&amp;pcflg=1">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-04.jpg"
                        alt="サイズオーダー対応商品"
                        border="0"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="bnr_box_base2">
                    <a href="https://kyno.jp/smp/freepage_detail.php?cid=1932&amp;fid=8&amp;pcflg=1">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-05.jpg"
                        alt="日本製"
                        border="0"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="bnr_box_base2">
                    <a href="https://kyno.jp/smp/freepage_detail.php?cid=1932&amp;fid=24&amp;pcflg=1">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-06.jpg"
                        alt="安心＆安全な製品づくり"
                        border="0"
                      />
                    </a>
                  </div>
                </li>
              </ul>
              <div className="top_sozai2">
                <div className="sozai_title">
                  <span>選べる無垢材</span>
                </div>
                <p>
                  製品には無垢材を使用しており、いくつかの材質の中からご希望の素材で製作いたします。
                  <br />
                  <span>
                    ※お選びいただける材質は「シリーズごと」「アイテムごと」などで異なります。
                  </span>
                </p>
                <div className="bnr_box">
                  <ul>
                    <li>
                      <div className="basebox">
                        <div className="basebox_child">
                          <img
                            src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/walnut.jpg"
                            alt="ウォールナット/ウォルナット"
                            border="0"
                          />
                        </div>
                        <div className="basebox_child">
                          <p className="sozai_name">
                            重量感と高級感のある
                            <br />
                            <strong>ウォールナット</strong>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="basebox">
                        <div className="basebox_child">
                          <img
                            src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/bkcherry.jpg"
                            alt="ブラックチェリー"
                            border="0"
                          />
                        </div>
                        <div className="basebox_child">
                          <p className="sozai_name">
                            付き合うごとに味がでる
                            <br />
                            <strong>ブラックチェリー</strong>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="basebox">
                        <div className="basebox_child">
                          <img
                            src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/whiteoak.jpg"
                            alt="ホワイトオーク"
                            border="0"
                          />
                        </div>
                        <div className="basebox_child">
                          <p className="sozai_name">
                            ナチュラルで温かみのある
                            <br />
                            <strong>ホワイトオーク</strong>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="basebox">
                        <div className="basebox_child">
                          <img
                            src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/whiteash.jpg"
                            alt="ホワイトアッシュ"
                            border="0"
                          />
                        </div>
                        <div className="basebox_child">
                          <p className="sozai_name">
                            清潔感のある色合いの
                            <br />
                            <strong>ホワイトアッシュ</strong>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="top_sozai">
                <p className="textcenter">
                  素材が気になる方に用する「無垢材」のサンプルお届けします。
                </p>
                <div className="basebox">
                  <div className="basebox_child">
                    <img
                      style={{ width: "68%" }}
                      src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/sample_ita_image.jpg"
                      alt="素材が気になる方に・・・"
                      border="0"
                    />
                  </div>
                  <div className="basebox_child btn_box">
                    <p className="link_button_silver green">
                      <a href="https://kyno.jp/SHOP/157427/list.html">
                        サンプル請求はこちら
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <section className="top_darkgray_section">
                <div className="top_darkgray_box">
                  <div className="contents20">
                    <div className="textcenter">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/BUYER-bnr.png"
                        alt="「Kurt」「Siifa」「Olivia」「Anita」「Colin」「Emil」シリーズの特徴"
                        align="baseline"
                      />
                    </div>
                    <div className="border_title">
                      「Kurt」「Siifa」「Olivia」「Anita」
                      <br />
                      「Colin」「Emil」「Tasia」
                      <br />
                      シリーズの特徴
                    </div>
                    <p style={{ paddingLeft: "3.2%" }}>
                      自社の家具バイヤーが海外の高品質な家具を中心に厳選したアイテムを取り扱っております。
                      <br />
                      見た目はもちろんのこと、品質、機能性などにもこだわっています。
                      <br />
                      ※一部国産のものもございます。
                    </p>
                    <ul className="explain_base_box3">
                      <li>
                        <div className="bnr_box_base2">
                          <a href="/">
                            <img
                              src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-07.jpg"
                              alt="随時商品入荷"
                              border="0"
                            />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="bnr_box_base2">
                          <a href="https://kyno.jp/smp/freepage_detail.php?cid=4147&amp;fid=5&amp;pcflg=1">
                            <img
                              src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-01.jpg"
                              alt="全国送料無料"
                              border="0"
                            />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="bnr_box_base2">
                          <a href="https://kyno.jp/smp/freepage_detail.php?cid=1932&amp;fid=298&amp;pcflg=1">
                            <img
                              src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/merit-icon-pc-08.jpg"
                              alt="自社バイヤー厳選商品"
                              border="0"
                            />
                          </a>
                        </div>
                      </li>
                    </ul>
                    <div className="bnr_box">
                      <ul className="bnr_box__ul">
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/230483/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner.jpg"
                                alt="Kurt/クルト"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/230526/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-siifa-sp.jpg"
                                alt="Siifa"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/230637/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-olivia.jpg"
                                alt="Olivia"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                      </ul>
                      <ul className="bnr_box__ul">
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/230978/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-antia.jpg"
                                alt="Anitaシリーズ"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/230981/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-colin.jpg"
                                alt="Colinシリーズ"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/230980/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-emil.jpg"
                                alt="Emilシリーズ"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                      </ul>
                      <ul className="bnr_box__ul">
                        <li>
                          <div className="bnr_box_base3">
                            <a href="https://kyno.jp/SHOP/231593/list.html">
                              <img
                                src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/siire-series-banner-tasia_img001.jpg"
                                alt="Tasiaシリーズ"
                                align="baseline"
                              />
                            </a>
                          </div>
                        </li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section className="hurusato_section">
              <div className="contents20">
                <h2 className="sns_title">FOLLOW US!</h2>
                <ul className="sns_main_box">
                  <li>
                    <a href="/">
                      <img
                        src="https://image1.shopserve.jp/kyno.jp/pic-labo/llimg/btn_youtube.png"
                        align="baseline"
                        alt="Youtube"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://kyno.jp/pic-labo/fb-logoicon.jpg"
                        align="baseline"
                        alt="FaceBook"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img
                        src="https://kyno.jp/pic-labo/pintar_logoicon.jpg"
                        align="baseline"
                        alt="ピンタレスト"
                      />
                    </a>
                  </li>
                  <li style={{ width: "62px" }}>
                    <a href="/">
                      <img
                        src="https://kyno.jp/pic-labo/instagram.jpg"
                        alt="インスタグラム　アイコン"
                        style={{ width: "55px" }}
                        align="baseline"
                        border="0"
                        hspace="0"
                        vspace="0"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </section>

            <section>
              <div className="footer_area">
                <div id="footer_bunner">
                  <h3 className="title1">お買いものガイド</h3>

                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }} valign="top">
                          <h5>お支払い方法</h5>
                          <p>
                            銀行振込（前払い）、郵貯銀行振込（前払い）クレジットカード払い、コンビニ決済（前払い）、
                            ギフトカード（前払い） がご利用いただけます。
                          </p>
                          <p className="kyoutyou">
                            <strong>払込ができる金融機関</strong>
                          </p>
                          <p className="ginkou">
                            西日本シティ銀行　大川支店
                            <br />
                            　口座番号：普通　１２３３８６５
                            <br />
                            　口座名義：シゼンコウボウ（カ
                          </p>
                          <p className="ginkou">
                            ゆうちょ銀行→ゆうちょ銀行への振替
                            <br />
                            　記号　17460
                            <br />
                            　番号　3304091
                            <br />
                            　口座名義　シゼンコウボウ（カ
                          </p>
                          <p className="ginkou">
                            他銀行→ゆうちょ銀行への振込
                            <br />
                            ゆうちょ銀行　9900
                            <br />
                            　店番　748
                            <br />
                            　種目　普通
                            <br />
                            　店名　七四八（ナナヨンハチ）
                            <br />
                            　口座番号　0330409
                            <br />
                            　口座名義　シゼンコウボウ（カ
                          </p>
                          <p className="kyoutyou">
                            <strong>ご利用可能クレジットカード</strong>
                          </p>
                          <p>
                            <img
                              alt="ご利用になれるクレジットカードなど"
                              src="https://kyno.jp/USERTHEMEIMG/vol1/kyno.rs.shopserve.jp//card_all.gif"
                            />
                          </p>
                          <p className="kyoutyou">
                            <strong>コンビニ決済で利用できるコンビニ</strong>
                          </p>
                          <p>
                            <img
                              alt="ご利用になれるコンビニ決済"
                              src="https://kyno.jp/USERTHEMEIMG/vol1/kyno.rs.shopserve.jp//cnv_l.gif"
                              width="270"
                            />
                          </p>
                          <p className="kyoutyou">
                            <strong>その他決済サービス</strong>
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <img
                              alt="PayPay"
                              src="https://kyno.jp/images/paypay_2_rgb.jpg"
                            />
                            <img
                              alt="amazonpay"
                              src="https://kyno.jp/images/Black-M.png"
                            />
                          </p>

                          <p>
                            ※お支払方法によって、当社より振込先や配送方法などの連絡を差し上げることがございます。
                          </p>
                          <p>
                            ※銀行及び郵貯銀行での振込手数料は、当社が負担いたしますので、手数料を引いた金額でお振込ください。
                          </p>
                          <p>
                            <strong>
                              ギフトカード(ＪＣＢなど)もご利用いただけます。
                            </strong>
                            <br />
                            ご利用可能なギフトカードについてはお問い合わせください。
                          </p>
                        </td>
                        <td>
                          <h5>仕入れ商品のキャンセルについて</h5>
                          <p>
                            自然工房では原則として、ご注文後の変更・キャンセルは承っておりません。あらかじめご了承の上、ご注文いただきますようお願いいたします。
                          </p>

                          <h5>送料・開梱設置について</h5>
                          <p>
                            <strong>
                              送料は「無料」（一部地域と一部商品を除く）
                            </strong>
                            です。
                            <a href="/">
                              一部地域…離島・特定地域（北海道・沖縄等）は別途送料がかかります。
                            </a>
                            その場合はご注文の際の自動送信メールの後、追ってお知らせいたします。
                            <br />
                            <br />
                            また、
                            <strong className="akaji">開梱設置は無料</strong>
                            です。ただし、
                            <a href="/">
                              階段昇降や吊るし作業が必要な時には、別途費用がかかる場合がございます。
                            </a>
                            <br />
                            <br />
                            なお、配送日の指定は可能ですが、
                            <strong>時間帯指定はできません</strong>。<br />
                            前日か当日の朝に運送会社よりご連絡を差し上げますので、お手数をお掛けいたしますがお時間の取れる日にちをご指定ください。
                          </p>

                          <h5>発送・配送について</h5>
                          <p className="akaji">
                            <strong>
                              受注生産のため、通常、注文日～発送まで40-50日ほどお時間を頂いております。
                            </strong>
                          </p>
                          <p>
                            ※在庫がある場合、ご入金確認後、２～４営業日以内に発送可能です。
                          </p>
                          <p className="akaji">ご注意</p>
                          <p>
                            ※運送会社の家財便でお届けしております。完成～お届けまで10日程お待ちいただく場合がございます。
                          </p>
                          <p>
                            ※受注生産のため、お急ぎの場合は、お早めにご注文ください。
                            <br />
                            ※大雪、台風などの天候や交通状況により、運送に遅れが生じる可能性がございます。
                          </p>
                          <p>
                            ※18時以降の中型・大型家具のお届けは原則行っておりません。（運送会社の都合上）
                          </p>

                          <h5>アフターフォローについて</h5>

                          <p>
                            受注生産に関する製品の場合、ご購入後の心配・不安をフォローいたします。
                            <br />
                            もし製品に不具合など起こりましたらお気軽にご連絡ください。
                            <br />
                            製品の種類によって（節や色味など）ご期待に沿えない場合がございます。予めご了承ください。
                            <br />
                            ※送料はお客様ご負担でお願いしております。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5>返品・交換について</h5>
                          <p>
                            通常商品、一般商品の返品・交換を承っております。
                            <br />
                            返品・交換をご希望のお客様は、商品到着から「７日以内」に、事前にメールまたはお電話にて必ずご連絡ください。
                          </p>
                          <p>
                            ※送料はお客様ご負担でお願いしております。（不良品、誤品配送の際、送料は当社負担で対応させていただきます。）
                          </p>
                          <p>
                            ※オーダーメイド製品及びセール品の返品はご遠慮ください。
                          </p>
                        </td>
                        <td>
                          <h5>在庫切れ商品について</h5>
                          <p>
                            完売商品をご希望の場合、状況をお調べいたしますので、下記の連絡先までご連絡下さい。
                          </p>
                          <h5>セールについて</h5>
                          <p>
                            セール商品は限定数量となっておりますので、売り切れとなる場合がございます。
                          </p>
                          <p>
                            受注生産によるオーダーメイド製品はセール対象外となる場合がございます。
                          </p>
                          <p>
                            セール商品の返品・交換はお受けできませんので、予めご了承ください。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5>連絡先</h5>
                          <p>
                            自然工房
                            天然木の家具工房直売ネットストア≪国産・送料無料・開梱設置無料≫
                          </p>
                          <p>運営責任者：TANYA UUOO LLC</p>
                          <p>
                            所在地：1309 Coffeen Avenue STE 1200，Sheridan,
                            Wyoming 82801
                          </p>
                          <p>TEL：(415) 425-9345</p>
                          <p>FAX：(415) 425-9345</p>
                          <p>URL:www.thinkless.live</p>
                          <p>
                            休業日：第２・４土曜日、日曜、祝祭日、GW、盆、年末年始
                          </p>
                        </td>
                        <td>
                          <h5 id="otoiawase">ご質問・お問い合わせについて</h5>
                          <p className="link_button_silver">
                            <a href="/">お問い合わせフォーム</a>
                            よりお願いいたします。
                          </p>
                          <p>
                            <br />
                            詳細な打ち合わせで、間取りの図面や画像などを送信できる方は
                            <br />
                          </p>
                          <p className="link_button_silver">
                            <a href="mailto:clear@thinkless.live">こちら</a>
                            にメールにて送信ください。
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Main;
